body.kl-theme-dark {
  color-scheme: dark;
  color: #ccc;
  background: #212121;
}

body.kl-theme-dark .kl-toolspace {
  background: #333;
}

body.kl-theme-dark .kl-toolspace--left {
  border-right: 1px solid #626262;
}

body.kl-theme-dark .kl-toolspace--right {
  border-left: 1px solid #626262;
}

body.kl-theme-dark .kl-toolspace-row {
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, .1) 100%);
  box-shadow: 0 1px 1px #252525;
}

body.kl-theme-dark .toolspace-row-button-activated {
  background: #6e6e6e;
}

body.kl-theme-dark .toolspace-row-button-hover {
  background: #535353;
}

body.kl-theme-dark .toolspace-row-button:active {
  background: #585858;
}

body.kl-theme-dark .toolspace-svg-triangle-button-hover {
  fill: #535353;
}

body.kl-theme-dark .toolspace-svg-triangle-button:active {
  fill: #585858;
}

body.kl-theme-dark .toolspace-row-button__submit:hover {
  background: #5786ff;
}

body.kl-theme-dark .tabrow {
  background: none;
  border-bottom: 1px solid #6e6e6e;
}

body.kl-theme-dark .tabrow__tab {
  color: #fff;
}

body.kl-theme-dark .tabrow__tab--opened, body.kl-theme-dark .tabrow__tab--opened-accented {
  background-color: #6e6e6e;
}

body.kl-theme-dark .tabrow__tab div, body.kl-theme-dark .tabrow__tab:after, body.kl-theme-dark .tabrow__tab:before {
  -webkit-filter: brightness(.45);
  filter: brightness(.45);
}

body.kl-theme-dark .tabrow__tab:not(.tabrow__tab--opened):not(.tabrow__tab--opened-accented):hover {
  background-color: #535353;
}

body.kl-theme-dark .tabrow__tab:active:not(.tabrow__tab--opened-accented):not(.tabrow__tab--opened) {
  background-color: #585858;
  box-shadow: inset 2px 2px 10px rgba(0, 0, 0, .2);
}

body.kl-theme-dark .kl-tool-row-border-right {
  border-right: 1px solid #303030;
}

body.kl-theme-dark .tool-dropdown-wrapper {
  background-color: #5e5e5e;
}

body.kl-theme-dark .tool-dropdown-button:hover {
  background: gray;
}

body.kl-theme-dark .kl-popup {
  background: rgba(33, 33, 33, .63);
}

body.kl-theme-dark .kl-popup-box {
  background-color: #333;
  box-shadow: 0 0 0 1px #636363;
}

body.kl-theme-dark .kl-d-modal-root {
  background: rgba(33, 33, 33, .63);
}

body.kl-theme-dark .kl-d-modal {
  background-color: #333;
  box-shadow: 0 0 0 1px #636363;
}

body.kl-theme-dark button {
  color: #ccc;
  box-shadow: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #4a4a4a;
  background-image: none;
}

body.kl-theme-dark button:hover {
  color: #eee;
  background-color: #555;
}

body.kl-theme-dark button:active {
  color: #fff;
  background-color: #555;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, .2);
}

body.kl-theme-dark button:disabled {
  color: #999;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
}

body.kl-theme-dark button:not(.kl-button-primary):not(.kl-button-cancel) img:not(.dark-no-invert) {
  -webkit-filter: invert() hue-rotate(180deg);
  filter: invert() hue-rotate(180deg);
}

body.kl-theme-dark input, body.kl-theme-dark textarea {
  color: #ccc;
  background: #333;
}

body.kl-theme-dark input:focus, body.kl-theme-dark textarea:focus {
  color: #fff;
  background: #222;
}

body.kl-theme-dark .kl-select {
  color: #fff;
  background: #222;
  border: 2px solid #666;
}

body.kl-theme-dark .kl-checkbox__inner {
  color: #ccc;
}

body.kl-theme-dark .kl-checkbox__inner:hover {
  color: #fff;
}

body.kl-theme-dark .kl-checkbox--highlight {
  box-shadow: 0 0 0 1px var(--active-highlight-color);
  background: none;
}

body.kl-theme-dark .kl-option {
  color: #ccc;
  box-shadow: inset 0 0 0 1px #606060;
}

body.kl-theme-dark .kl-option-selected {
  color: #fff;
  box-shadow: inset 0 0 0 1px var(--active-highlight-color);
  background: rgba(255, 255, 255, .07);
}

body.kl-theme-dark .kl-box-toggle {
  color: #ccc;
  box-shadow: inset 0 0 0 1px #606060;
}

body.kl-theme-dark .kl-box-toggle--active {
  color: #fff;
  box-shadow: inset 0 0 0 1px var(--active-highlight-color);
  background: rgba(255, 255, 255, .07);
}

body.kl-theme-dark .dark-invert {
  -webkit-filter: invert();
  filter: invert();
}

body.kl-theme-dark .kl-file-no-autosave {
  color: #c9b184;
  background: none;
  box-shadow: inset 0 0 0 1px;
}

body.kl-theme-dark .slider-wrapper {
  color: #ddd;
  text-shadow: none;
  background: #222;
  box-shadow: 0 0 0 1px #777;
}

body.kl-theme-dark .slider-wrapper .slider-inner {
  background: #555;
  box-shadow: 1px 0 #777;
}

body.kl-theme-dark .slider-wrapper--active {
  color: #fff;
  box-shadow: 0 0 0 1px #aaa;
}

body.kl-theme-dark .slider-wrapper--active .slider-inner {
  box-shadow: 1px 0 #aaa;
}

body.kl-theme-dark .slider-wrapper--disabled {
  box-shadow: none;
  color: #999;
}

body.kl-theme-dark .info-hint {
  color: #f5a7a7;
  background: none;
  border: 1px solid #a66969;
}

body.kl-theme-dark .kl-2-tabs .kl-2-tabs__left, body.kl-theme-dark .kl-2-tabs .kl-2-tabs__right {
  color: #ccc;
  border: 1px solid rgba(113, 112, 112, .3);
  border-bottom: none;
}

body.kl-theme-dark .kl-2-tabs .kl-2-tabs__left:hover, body.kl-theme-dark .kl-2-tabs .kl-2-tabs__right:hover {
  background: #444;
}

body.kl-theme-dark .kl-2-tabs .kl-2-tabs__left {
  border-right: none;
  border-top-left-radius: 10px;
}

body.kl-theme-dark .kl-2-tabs .kl-2-tabs__right {
  border-left: none;
  border-top-right-radius: 10px;
}

body.kl-theme-dark .kl-2-tabs .kl-2-tabs--active {
  color: #fff;
  border: 1px solid var(--active-highlight-color);
  background: #444;
  border-bottom: none;
}

body.kl-theme-dark .kl-2-tabs .kl-2-tabs--active:hover {
  background: #fff;
}

body.kl-theme-dark .popup-x {
  -webkit-filter: invert();
  filter: invert();
}

body.kl-theme-dark .popup-x:hover {
  -webkit-filter: invert() brightness(1.4);
  filter: invert() brightness(1.4);
}

body.kl-theme-dark .kl-preview-wrapper {
  background: #212121;
  box-shadow: inset 0 1px #636363, inset 0 -1px #636363;
}

body.kl-theme-dark .kl-preview-wrapper .kl-preview-wrapper__canvas {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, .25);
}

body.kl-theme-dark .kl-edit-crop-preview {
  border-top: 1px solid #636363;
  border-bottom: 1px solid #636363;
}

body.kl-theme-dark .kl-transparent-preview {
  box-shadow: inset 0 1px #636363, inset 0 -1px #636363;
}

body.kl-theme-dark .kl-transparent-preview .kl-transparent-preview__canvas {
  box-shadow: 0 0 0 1px #777;
}

body.kl-theme-dark .kl-text-preview-wrapper {
  box-shadow: inset 0 1px #636363, inset 0 -1px #636363;
}

body.kl-theme-dark .kl-merge-preview {
  box-shadow: 0 0 0 1px #636363;
}

body.kl-theme-dark a:link, body.kl-theme-dark a:not([href]), body.kl-theme-dark a:visited {
  color: #64acff;
}

body.kl-theme-dark a:link:hover, body.kl-theme-dark a:not([href]):hover, body.kl-theme-dark a:visited:hover {
  color: #b2e8ff;
}

body.kl-theme-dark .kl-color-picker__h, body.kl-theme-dark .kl-color-picker__sv, body.kl-theme-dark .kl-color-picker__preview {
  box-shadow: 0 0 0 1px #646464;
}

body.kl-theme-dark .kl-color-picker--active {
  box-shadow: 0 0 0 1px #999;
}

body.kl-theme-dark .kl-color-picker__secondary {
  box-shadow: 0 0 0 1px #7a7a7a;
}

body.kl-theme-dark .kl-stabilizer {
  color: #ccc;
}

body.kl-theme-dark .brush-alpha, body.kl-theme-dark .brush-alpha--selected {
  -webkit-filter: invert() hue-rotate(180deg);
  filter: invert() hue-rotate(180deg);
}

body.kl-theme-dark .kl-layer-preview {
  color: #ccc;
}

body.kl-theme-dark .kl-layer-preview canvas {
  box-shadow: 0 0 0 1px #7a7a7a;
}

body.kl-theme-dark .kl-import-note, body.kl-theme-dark .kl-toolspace-note {
  color: #d5d558;
  background: none;
  border: 1px solid #d5d558;
}

body.kl-theme-dark .kl-curves-graph canvas {
  -webkit-filter: invert() hue-rotate(180deg);
  filter: invert() hue-rotate(180deg);
  background: #dedede;
}

body.kl-theme-dark .kl-curves-graph__grip {
  box-shadow: none;
  background: #fff;
}

body.kl-theme-dark .kl-storage-preview {
  color: #aaa;
  background: #212121;
  box-shadow: inset 0 0 0 1px #5e5e5e;
}

body.kl-theme-dark .kl-storage-preview__im {
  box-shadow: 0 0 0 1px #5e5e5e;
}

body.kl-theme-dark .kl-storage-about {
  box-shadow: inset 0 0 0 1px #fff;
}

body.kl-theme-dark .kl-layer {
  background: none;
  border: 1px solid #4f4f4f;
}

body.kl-theme-dark .kl-layer .kl-layer__label, body.kl-theme-dark .kl-layer .kl-layer__opacity-label {
  color: #ccc;
}

body.kl-theme-dark .kl-layer--selected {
  border: 1px solid var(--active-highlight-color);
  background: #505050;
}

body.kl-theme-dark .kl-layer--selected .kl-layer__label {
  color: #fff;
}

body.kl-theme-dark .kl-point-slider__line {
  background: #777;
}

body.kl-theme-dark .kl-point-slider__point {
  box-shadow: none;
}

body.kl-theme-dark .kl-overlay-toolspace {
  box-shadow: none;
  background: none;
  border: none;
}

body.kl-theme-dark .kl-overlay-toolspace__color {
  box-shadow: 0 0 0 1px #6e6e6e;
}

body.kl-theme-dark .image-toggle-active {
  background: rgba(255, 255, 255, .07);
}

body.kl-theme-dark .kl-file-save-row {
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .2);
}

body.kl-theme-dark .grid-hr {
  border-top: none;
  border-bottom: 1px solid rgba(255, 255, 255, .133);
}

body.kl-theme-dark .kl-color-option {
  box-shadow: 0 0 0 1px #7a7a7a;
}

body.kl-theme-dark .kl-color-option--active {
  box-shadow: 0 0 0 2px var(--active-highlight-color), 0 0 5px 0 var(--active-highlight-color);
}

body.kl-theme-dark .kl-text-error {
  color: #ff3838;
}

:root {
  --active-highlight-color: #6e9ef8;
  --body-bg-color: #9e9e9e;
  --kl-color: #2a64f8;
}

@-webkit-keyframes consoleIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes consoleIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes consoleInFast {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }
}

@keyframes consoleInFast {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 1;
  }
}

@-webkit-keyframes consoleOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes consoleOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

body {
  overscroll-behavior-x: none;
  color-scheme: light;
  font-size: 16px;
  overflow: hidden;
}

body, html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  touch-action: none;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

.g-root {
  font-family: Arial, sans-serif;
  font-size: 16px;
}

a:link, a:not([href]) {
  color: var(--kl-color);
  cursor: pointer;
  text-decoration: underline;
}

a:visited {
  color: #536184;
  text-decoration: underline;
}

a:hover, a:not([href]):hover {
  color: #000;
  text-decoration: underline;
}

.kl-select {
  background: #fff;
}

.grid-hr {
  clear: both;
  border-top: 1px solid rgba(0, 0, 0, .15);
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}

.kl-toolspace {
  background-color: #ddd;
}

.kl-toolspace--left {
  border-right: 1px solid #878787;
}

.kl-toolspace--right {
  border-left: 1px solid #878787;
}

.kl-toolspace-row {
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, .6) 100%);
  box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 1px #cecece;
}

.nohighlight {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.toolspace-row-button {
  cursor: pointer;
  flex-grow: 1;
}

.toolspace-triangle-button {
  cursor: pointer;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  position: absolute;
  top: 0;
  left: 0;
}

.toolspace-svg-triangle-button {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.toolspace-row-button-hover {
  background: rgba(255, 255, 255, .7);
}

.toolspace-row-button:active, .toolspace-triangle-button:active {
  background-color: #ddd;
  box-shadow: inset 2px 2px 10px rgba(0, 0, 0, .2) !important;
}

.toolspace-svg-triangle-button-hover {
  fill: rgba(255, 255, 255, .7);
}

.toolspace-svg-triangle-button:active {
  fill: #ddd;
  -webkit-filter: url("#innershadow");
  filter: url("#innershadow");
}

.toolspace-row-button-activated {
  box-shadow: inset 0 2px 0 0 var(--active-highlight-color);
  pointer-events: none;
  background: #fff;
}

.toolspace-row-button-activated div {
  opacity: 1 !important;
}

.toolspace-row-button-disabled {
  opacity: .5;
  pointer-events: none;
}

.kl-tool-row-border-right {
  border-right: 1px solid #d4d4d4;
}

.kl-color-picker__h, .kl-color-picker__sv, .kl-color-picker__preview {
  box-shadow: 0 0 0 1px #bcbcbc;
}

.kl-color-picker--active {
  z-index: 1;
  box-shadow: 0 0 0 1px #fff;
}

.kl-color-picker__secondary {
  box-shadow: 0 0 0 1px #bcbcbc;
}

.color-picker-preview-button {
  cursor: pointer;
  color: #000;
  opacity: .8;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.color-picker-preview-button-hover {
  opacity: 1;
  background-color: #fff;
  -webkit-filter: none !important;
  filter: none !important;
}

.color-picker-preview-button-active {
  opacity: 1;
  box-shadow: inset 0 2px 0 0 var(--active-highlight-color);
  background-color: #fff;
  -webkit-filter: none !important;
  filter: none !important;
}

.slider-wrapper {
  text-shadow: 0 0 5px rgba(255, 255, 255, .5);
  color: #333;
  cursor: ew-resize;
  background: linear-gradient(#909090 1%, #b3b3b3 73%);
  border-radius: 8px;
  box-shadow: .5px 1px rgba(255, 255, 255, .8);
}

.slider-wrapper--active {
  color: #000;
  background: linear-gradient(#7a7a7a 1%, #939393 73%);
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, .2);
}

.slider-wrapper--active .slider-inner {
  background: #fff;
  box-shadow: 2px 2px 10px #919191;
}

.slider-inner {
  background-color: #fff;
  background-image: linear-gradient(to top, #e8e8e8 0%, #fff 70%);
  border-radius: 6px 0 0;
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, .3), 2px 2px 6px rgba(145, 145, 145, .49);
}

.kl-point-slider__line {
  height: 2px;
  background: #aaa;
}

.kl-point-slider__point {
  cursor: ew-resize;
  background-color: #eaeaea;
  transition: box-shadow .2s ease-in-out;
  position: absolute;
  top: 0;
  box-shadow: 0 0 3px rgba(0, 0, 0, .8);
}

.kl-point-slider__point:active {
  box-shadow: 0 0 6px #000;
}

button {
  min-width: 30px;
  color: #666;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #f3f3f3;
  background-image: linear-gradient(to top, rgba(111, 111, 111, .1) 0%, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, .3) 100%);
  border: none;
  border-radius: 3px;
  padding: 5px;
  font-size: 15px;
  line-height: 17px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .04), 0 1px rgba(0, 0, 0, .1), 0 2px 3px rgba(0, 0, 0, .05);
}

button:hover {
  color: #777;
  cursor: pointer;
  background-color: #fff;
}

button:active {
  background-color: #ddd;
  box-shadow: inset 2px 2px 10px rgba(0, 0, 0, .2), 0 2px 2px #ddd;
}

button:disabled {
  min-width: 30px;
  color: #999;
  cursor: default;
  background-color: #ddd;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, .3) 100%);
  border: none;
  border-radius: 3px;
  padding: 5px;
  font-size: 15px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .05);
}

button:disabled img {
  opacity: .2;
}

button img {
  float: left;
  pointer-events: none;
}

.kl-button-primary {
  background: var(--kl-color);
  color: #fff;
}

.kl-button-primary:hover {
  background-image: linear-gradient(to top, var(--kl-color) -50%, var(--active-highlight-color) 150%);
  color: #fff;
}

.kl-button-primary:active {
  background-image: linear-gradient(to top, var(--kl-color) -50%, var(--active-highlight-color) 120%);
  color: #fff;
  box-shadow: unset;
}

.grid-button {
  width: 120px;
  margin-top: 10px;
  margin-left: 10px;
}

.kl-popup {
  width: 100%;
  height: 100%;
  touch-action: pan-y;
  background: rgba(50, 50, 50, .5);
  -webkit-animation-name: consoleIn;
  animation-name: consoleIn;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  overflow-x: hidden;
  overflow-y: auto;
}

.kl-popup-box {
  background-color: #ddd;
  border-radius: 10px;
  margin: 10px 0;
  padding: 20px;
  position: relative;
  box-shadow: 0 5px 60px rgba(0, 0, 0, .45);
}

.kl-popup-box--sm {
  width: 300px;
}

.kl-popup__btn {
  min-width: 80px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  margin-left: 8px;
  display: flex;
}

.kl-popup__btn img {
  margin-left: 2px;
  margin-right: 5px;
}

.kl-popup__btn__text {
  text-align: center;
  flex-grow: 1;
}

.poperror, .popok, .popwarning, .popupload {
  background-position: 20px 20px;
  background-repeat: no-repeat;
  padding-left: 104px;
}

@media (max-width: 600px) {
  .poperror, .popok, .popwarning, .popupload {
    background-position: 10px 20px;
    padding-left: 35px;
    background-size: 20px 20px !important;
  }
}

.poperror {
  background-image: url("error.abae1a5e.svg");
  background-size: 60px;
}

.popok {
  background-image: url("ok.398afd66.svg");
  background-size: 60px;
}

.popwarning {
  background-image: url("warning.7472a070.svg");
  background-size: 60px;
}

.popupload {
  background-image: url("upload-large.bb0f452b.svg");
  background-size: 60px;
}

.kl-d-modal-root {
  background: rgba(0, 0, 0, .45);
}

.kl-d-modal {
  background: #eee;
  box-shadow: 0 5px 60px rgba(0, 0, 0, .25);
}

@-webkit-keyframes topOverlayPulse {
  10% {
    background-color: var(--kl-color);
    padding-top: 12px;
  }

  50% {
    background-color: var(--kl-color);
    padding-top: 7px;
  }
}

@keyframes topOverlayPulse {
  10% {
    background-color: var(--kl-color);
    padding-top: 12px;
  }

  50% {
    background-color: var(--kl-color);
    padding-top: 7px;
  }
}

.top-overlay {
  width: calc(100% - 271px);
  z-index: 100;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  justify-content: center;
  -webkit-animation-duration: .4s;
  animation-duration: .4s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.top-overlay--inner {
  text-align: center;
  color: #fff;
  background-color: rgba(0, 0, 0, .4);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 7px 10px;
}

.kl-checkbox {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  display: block;
}

.kl-checkbox--highlight {
  background: #bed5ff;
}

.kl-checkbox__inner {
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
}

.kl-checkbox__inner:hover {
  color: #000;
}

.kl-layer {
  width: 250px;
  height: 34px;
  box-sizing: border-box;
  background-color: #dcdcdc;
  background-image: linear-gradient(to top, rgba(188, 188, 188, .1) 0%, rgba(255, 255, 255, .3) 100%);
  border: 1px solid #9e9e9e;
  border-radius: 5px;
  transition: all .1s linear;
  position: absolute;
  left: 0;
}

.kl-layer .kl-layer__label, .kl-layer .kl-layer__opacity-label {
  color: #666;
}

.kl-layer--selected {
  border: 1px solid var(--active-highlight-color);
  background: #fff;
}

.kl-layer--selected .kl-layer__label {
  color: #000;
}

.info-hint {
  background: #ff8473;
  border: 1px solid #d00000;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

textarea, input {
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #b2b2b2;
  padding: 5px;
  font-size: .95em;
}

textarea:focus, input:focus {
  background: #fff;
  border: 1px solid #636363;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
}

.kl-textarea::-webkit-input-placeholder, .kl-input::-webkit-input-placeholder {
  color: #929292;
}

.kl-textarea::placeholder, .kl-input::placeholder {
  color: #929292;
}

.popup-x {
  color: #888;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-filter: brightness(.9);
  filter: brightness(.9);
  justify-content: center;
  align-items: center;
  display: flex;
}

.popup-x:hover {
  color: #000;
  -webkit-filter: brightness(.5);
  filter: brightness(.5);
}

.brush-alpha, .brush-alpha--selected {
  width: 35px;
  height: 35px;
  cursor: pointer;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: contain;
  border: 1px solid #aaa;
  margin-right: 1px;
  display: inline-block;
}

.brush-alpha {
  opacity: .4;
  cursor: pointer;
}

.brush-alpha--selected {
  opacity: 1;
  cursor: default;
  background-color: #fff;
  border: 1px solid #76a6ff;
}

iframe {
  box-sizing: border-box;
  border: none;
}

.tabrow {
  background: linear-gradient(to top, #d0d0d0 0%, rgba(208, 208, 208, 0) 80%);
  border-bottom: 1px solid rgba(255, 255, 255, .7);
  display: flex;
}

.tabrow__tab {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: pointer;
  height: 100%;
  text-align: center;
  opacity: .6;
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  flex-grow: 1;
}

.tabrow__tab-hover {
  background-color: #fff;
}

.tabrow__tab:active:not(.tabrow__tab--opened-accented):not(.tabrow__tab--opened) {
  background-color: #ddd;
  box-shadow: inset 2px 2px 10px rgba(0, 0, 0, .2), 0 2px 2px #ddd;
}

.tabrow__tab--opened, .tabrow__tab--opened-accented {
  opacity: 1;
  cursor: default;
  background-color: #f5f5f5;
  position: relative;
}

.tabrow__tab--opened-accented {
  box-shadow: inset 0 2px 0 0 var(--active-highlight-color);
}

.tabrow__tab--opened-accented:not(:first-child):before, .tabrow__tab--opened:not(:first-child):before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 8px solid #f5f5f5;
  border-left: 2px solid rgba(0, 0, 0, 0);
  border-right: 0 solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
  left: -1.5px;
}

.tabrow__tab--opened-accented:not(:last-child):after, .tabrow__tab--opened:not(:last-child):after {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 8px solid #f5f5f5;
  border-left: 0 solid rgba(0, 0, 0, 0);
  border-right: 2px solid rgba(0, 0, 0, 0);
  position: absolute;
  bottom: 0;
  right: -1.5px;
}

.kl-option-wrapper {
  flex-wrap: wrap;
  margin-top: -4px;
  margin-left: -4px;
  display: flex;
}

.kl-option {
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 4px;
  margin: 4px 0 0 4px;
  padding: 6px 10px;
  font-size: 16px;
  transition: all .1s ease-in-out;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .3);
}

.kl-option--small {
  font-size: 14px;
}

.kl-option--custom-el {
  padding: 0;
}

.kl-option--custom-el:not(.kl-option-selected) > * {
  opacity: .5;
}

.kl-option-selected {
  color: #000;
  box-shadow: inset 0 0 0 1px var(--active-highlight-color);
  cursor: default;
  background: #fff;
}

.kl-box-toggle {
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 4px;
  padding: 6px 10px;
  font-size: 16px;
  transition: all .1s ease-in-out;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .3);
}

.kl-box-toggle--active {
  color: #000;
  box-shadow: inset 0 0 0 1px var(--active-highlight-color);
  cursor: default;
  background: #fff;
}

.kl-box-toggle--custom-el {
  padding: 0;
}

.kl-box-toggle--custom-el:not(.kl-box-toggle--active) > * {
  opacity: .5;
}

.kl-color-option {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  text-align: center;
  color: #aaa;
  margin-left: 7px;
  box-shadow: 0 0 0 1px #aaa;
}

.kl-color-option--active {
  box-shadow: 0 0 0 2px var(--active-highlight-color), 0 0 5px 0 var(--active-highlight-color);
  pointer-events: none;
}

.tool-dropdown-wrapper {
  background: #f1f1f1;
  -webkit-animation-name: dropdownOpen;
  animation-name: dropdownOpen;
  -webkit-animation-duration: .1s;
  animation-duration: .1s;
  -webkit-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  box-shadow: 0 2px 5px rgba(0, 0, 0, .5);
}

.tool-dropdown-button:hover {
  background: #fff;
}

.tool-dropdown-button:active {
  background-color: #ddd;
  box-shadow: inset 2px 2px 10px rgba(0, 0, 0, .2);
}

@-webkit-keyframes dropdownOpen {
  from {
    height: 0;
    opacity: 0;
  }
}

@keyframes dropdownOpen {
  from {
    height: 0;
    opacity: 0;
  }
}

.image-toggle {
  width: 23px;
  height: 23px;
  cursor: pointer;
}

.image-toggle:not(.image-toggle-active) {
  opacity: .4;
  box-shadow: 0 0 0 1px #838383;
}

.image-toggle-active {
  box-shadow: var(--active-highlight-color) 0 0 0 2px;
  background-color: #fff;
}

.image-toggle__im {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 83%;
}

.image-radio-wrapper {
  display: flex;
}

.image-radio-wrapper .image-toggle:not(:first-child) {
  margin-left: 7px;
}

.image-radio-wrapper .image-toggle-active {
  cursor: default;
}

.kl-radio label {
  cursor: pointer;
}

.kl-radio label:not(:last-child) {
  margin-right: 10px;
}

.kl-scroller {
  cursor: pointer;
  -webkit-filter: invert();
  filter: invert();
  width: 36px;
  height: 36px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: rgba(155, 155, 155, .9);
  background-image: url("caret-down.68d4cd16.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  position: fixed;
}

.kl-scroller:active {
  background-color: rgba(180, 180, 180, .9);
}

.kl-table {
  border-spacing: 0;
}

.kl-table td {
  padding: 0;
}

.kl-2-tabs {
  justify-content: center;
  display: flex;
}

.kl-2-tabs .kl-2-tabs__left, .kl-2-tabs .kl-2-tabs__right {
  width: 150px;
  text-align: center;
  cursor: pointer;
  color: rgba(0, 0, 0, .6);
  border: 1px solid rgba(0, 0, 0, .6);
  border-bottom: none;
  padding: 10px 5px;
  transition: background .1s ease-in-out;
}

.kl-2-tabs .kl-2-tabs__left:hover, .kl-2-tabs .kl-2-tabs__right:hover {
  background: #eee;
}

.kl-2-tabs .kl-2-tabs__left {
  border-right: none;
  border-top-left-radius: 10px;
}

.kl-2-tabs .kl-2-tabs__right {
  border-left: none;
  border-top-right-radius: 10px;
}

.kl-2-tabs .kl-2-tabs--active {
  color: #000;
  border: 1px solid var(--active-highlight-color);
  pointer-events: none;
  background: #fff;
  border-bottom: none;
}

.kl-2-tabs .kl-2-tabs--active:hover {
  background: #fff;
}

.kl-layer-preview {
  color: #555;
}

.kl-layer-preview canvas {
  box-shadow: 0 0 0 1px #9e9e9e;
}

.kl-edit-crop-preview {
  border-top: 1px solid #bbb;
  border-bottom: 1px solid #bbb;
}

.kl-edit-crop-preview__sel {
  position: absolute;
  box-shadow: 0 0 0 1px #fff, 0 0 0 2px #000, 0 0 40px 1px #000;
}

.kl-text-error {
  color: red;
}

.kl-file-no-autosave {
  text-align: center;
  color: rgba(0, 0, 0, .65);
  background: #f3f3a1;
  margin-top: 10px;
  padding: 5px;
  font-size: 15px;
}

.kl-preview-wrapper {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: #9e9e9e;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-left: -20px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 1px rgba(0, 0, 0, .2), inset 0 -1px rgba(0, 0, 0, .2);
}

.kl-preview-wrapper__canvas {
  position: relative;
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);
}

.kl-transparent-preview {
  box-shadow: inset 0 1px rgba(0, 0, 0, .2), inset 0 -1px rgba(0, 0, 0, .2);
}

.kl-transparent-preview__canvas {
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);
}

.kl-text-preview-wrapper {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: inset 0 1px rgba(0, 0, 0, .2), inset 0 -1px rgba(0, 0, 0, .2);
}

.kl-merge-preview {
  margin-left: auto;
  margin-right: auto;
  display: block;
  box-shadow: 0 0 3px rgba(0, 0, 0, .5);
}

.kl-stabilizer {
  color: rgba(0, 0, 0, .6);
  align-items: center;
  margin-right: -1px;
  font-size: 13px;
  display: flex;
}

.kl-toolspace-note {
  color: #534a3c;
  text-align: center;
  background: rgba(255, 255, 0, .5);
  border: 1px solid #e7d321;
  border-radius: 5px;
  padding: 8px;
}

.kl-import-note {
  background: rgba(255, 255, 0, .5);
  border: 1px solid #e7d321;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 8px;
}

.kl-curves-graph canvas {
  background: #c6c6c6;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, .3);
}

.kl-curves-graph__grip {
  cursor: move;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  touch-action: none;
  background: #fff;
  position: absolute;
  box-shadow: inset 0 0 0 2px #000;
}

.kl-storage-preview {
  color: #545454;
  min-height: 67px;
  background: #cdcdcd;
  grid-area: preview;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: flex;
  position: relative;
  box-shadow: inset 0 0 0 1px #aaa;
}

.kl-storage-preview__im {
  max-width: calc(100% - 2px);
  max-height: calc(100% - 2px);
  pointer-events: none;
  margin: 0 auto;
  display: block;
  box-shadow: 0 0 0 1px #aaa;
}

.kl-storage-about {
  cursor: pointer;
  width: 19px;
  height: 19px;
  text-align: center;
  border-radius: 100%;
  margin-left: 5px;
  font-weight: bold;
  line-height: 19px;
  box-shadow: inset 0 0 0 1px #000;
}

.kl-overlay-toolspace {
  background: #ddd;
  border: 1px solid #fff;
  display: none;
  position: absolute;
  top: 500px;
  left: 500px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .5);
}

.kl-file-save-row {
  border-radius: 5px;
  margin: 10px 10px 0;
  padding-bottom: 10px;
  padding-right: 10px;
  display: flex;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .2);
}

/*# sourceMappingURL=index.4c6038ac.css.map */
